import React, { createContext, useState, useRef } from 'react'
import useStorage from '../hooks/useStorage'
import versions from '../config/versions'

interface Context {
  isAuth: boolean
  termsAgreed: boolean
  deviceId: string | null
  lastRequestTime: () => number | null
  setDeviceId: (deviceId: string | null) => void
  setAgreedTermsVersion: (version: number | null) => void
  isDemo: () => boolean
  setDemo: (demo: boolean) => void
  signOut: () => void
  refreshRequestTime: () => void
  updateAuthState: (authenticated: boolean) => void
}

const defaultValues = {
  termsAgreed: false,
  isAuth: false,
  deviceId: null,
  lastRequestTime: () => null,
  setDeviceId: () => {},
  setAgreedTermsVersion: () => {},
  isDemo: () => false,
  setDemo: () => {},
  signOut: () => {},
  refreshRequestTime: () => { },
  updateAuthState: () => {}
}

export const AuthContext = createContext<Context>(defaultValues)

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const storage = useStorage()
  const lastRequestTimeRef = useRef<number | null>(null)
  const [isAuth, setAuth] = useState<boolean>(false)
  const [deviceId, _setDeviceId] = useState<Context['deviceId']>(storage.getDeviceId())
  const [agreedTermsVersion, _setAgreedTermsVersion] = useState<number | null>(null)
  const isDemoRef = useRef<boolean>(storage.getDemo())

  const setDeviceId = (deviceId: string | null): void => {
    _setDeviceId(deviceId)
    storage.setDeviceId(deviceId)
  }

  const setAgreedTermsVersion = (version: number | null): void => {
    console.log('Setting agreed terms version:', version)
    _setAgreedTermsVersion(version)
    // storage.setAgreedTermsVersion(version)
  }

  const setDemo = (demo: boolean): void => {
    storage.setDemo(demo)
    isDemoRef.current = demo
  }

  const signOut = (): void => {
    lastRequestTimeRef.current = null
    setDeviceId(null)
    setAgreedTermsVersion(null)
    setDemo(false)
    setAuth(false)
  }

  const refreshRequestTime = (): void => {
    lastRequestTimeRef.current = Date.now()
  }

  const updateAuthState = (authenticated: boolean): void => {
    setAuth(authenticated)
  }

  const termsAgreedValue = isDemoRef.current || agreedTermsVersion === versions.termsOfService

  return (
    <AuthContext.Provider
      value={{
        isAuth,
        updateAuthState,
        deviceId,
        termsAgreed: termsAgreedValue,
        lastRequestTime: () => lastRequestTimeRef.current,
        setDeviceId,
        setAgreedTermsVersion,
        isDemo: () => isDemoRef.current,
        setDemo,
        signOut,
        refreshRequestTime
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
