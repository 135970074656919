import { useContext, useState } from 'react'
import useApi from './useApi'
import { ResourceContext } from '../contexts/ResourceContext'
import SalaryPeriodSummary from '../types/SalaryPeriodSummary'
import SalaryDetails from '../types/SalaryDetails'
import SalaryYearSummary from '../types/SalaryYearSummary'
import { SalaryContactProps } from '../types/SalaryContact'
import SalaryConfig from '../types/SalaryConfig'
import { LocaleContext } from '../contexts/LocaleContext'
import useLocalization from './useLocalization'

interface ReturnProps {
  postForm: (form: SalaryContactProps) => Promise<boolean>
  getConfig: () => Promise<void>
  getYearPeriodSummaries: (year: number) => Promise<void>
  getDetails: (companyId: string, payday: string, employmentId: string) => Promise<void>
  getSummaries: () => Promise<void>
  config: SalaryConfig | null
  yearPeriodSummaries: SalaryPeriodSummary[] | null
  details: SalaryDetails | null
  yearSummaries: SalaryYearSummary[] | null
  periodSummaries: SalaryPeriodSummary[] | null
  getPdf: (companyId?: string, payday?: string, employmentId?: string, locale?: string) => Promise<boolean>
  loading: boolean
  loadingPdf: boolean
  error: string | null
}

const useSalaries = (): ReturnProps => {
  const [loadingConfig, setLoadingConfig] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingPdf, setLoadingPdf] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const { get, post, endpoints, getFile } = useApi()
  const { locale } = useContext(LocaleContext)
  const { t } = useLocalization()
  const {
    setSalaryConfig,
    setSalaryYearPeriodSummaries,
    setSalaryDetails,
    setSalaryYearSummaries,
    setSalaryPeriodSummaries,
    salaryConfig,
    salaryYearPeriodSummaries,
    salaryDetails,
    salaryYearSummaries,
    salaryPeriodSummaries
  } = useContext(ResourceContext)
  const [currentYear, setCurrentYear] = useState<number | null>(null)
  const [currentKey, setCurrentKey] = useState<string | null>(null)

  const getPdf = async (
    companyId?: string,
    payday?: string,
    employmentId?: string
  ): Promise<boolean> => {
    const path = endpoints.salaryPdfPath
      .replace('{companyId}', companyId ?? '')
      .replace('{payday}', payday ?? '')
      .replace('{employmentId}', employmentId ?? '')
      .replace('{language}', locale)
    setLoadingPdf(true)
    try {
      setError(null)
      await getFile(path, t('pdf.success.filename').replace('{date}', payday ?? ''))
      setLoadingPdf(false)
      return true
    } catch (error) {
      setError(error instanceof Error ? error.message : String(error))
      setLoadingPdf(false)
      return false
    }
  }

  const postForm = async (form: SalaryContactProps): Promise<boolean> => {
    setLoading(true)
    setError(null)

    const payload = {
      CompanyName: form.CompanyName,
      ContactPerson: form.ContactName,
      SendersAddress: form.Email
    }

    try {
      await post(endpoints.sendMailPath, JSON.stringify(payload))
      setLoading(false)
      return true
    } catch (error) {
      setLoading(false)
      setError(error instanceof Error ? error.message : String(error))
      return false
    }
  }

  const salaryKey = (companyId: string, payday: string, employmentId: string): string => {
    return `${companyId}-${payday}-${employmentId}`
  }

  const getConfig = async (): Promise<void> => {
    if (salaryConfig !== null) {
      return
    }
    setLoadingConfig(true)
    get(endpoints.salaryConfigPath).then(data => {
      const salaryConfig = data as SalaryConfig
      setSalaryConfig(salaryConfig)
    }).catch(error => {
      setError(error instanceof Error ? error.message : String(error))
    }).finally(() => {
      setLoadingConfig(false)
    })
  }

  const getDetails = async (companyId: string, payday: string, employmentId: string): Promise<void> => {
    const key = salaryKey(companyId, payday, employmentId)
    setCurrentKey(key)
    if (salaryDetails[key] !== undefined) {
      return
    }
    setLoading(true)
    const path = endpoints.singleSalaryPath
      .replace('{companyId}', companyId)
      .replace('{payday}', payday)
      .replace('{employmentId}', employmentId)
    get(path).then(data => {
      const salaryData = data.Details as SalaryDetails
      setSalaryDetails({ ...salaryDetails, [key]: salaryData })
    }).catch(error => {
      setError(error instanceof Error ? error.message : String(error))
    }).finally(() => {
      setLoading(false)
    })
  }

  const getYearPeriodSummaries = async (year: number): Promise<void> => {
    setCurrentYear(year)
    if (salaryYearPeriodSummaries[year] !== undefined) {
      return
    }
    setLoading(true)
    const path = endpoints.yearSalariesPath.replace('{year}', year.toString(10))
    get(path).then(data => {
      const salaryData = data.Salaries.PeriodSummary as SalaryPeriodSummary[]
      setSalaryYearPeriodSummaries({ ...salaryYearPeriodSummaries, [year]: salaryData })
    }).catch(error => {
      setError(error instanceof Error ? error.message : String(error))
    }).finally(() => {
      setLoading(false)
    })
  }

  const getSummaries = async (): Promise<void> => {
    if (salaryYearSummaries !== null && salaryPeriodSummaries !== null) {
      return
    }
    setLoading(true)
    get(endpoints.salariesPath).then(data => {
      const periodSummaries = data.Salaries?.PeriodSummary ?? [] as SalaryPeriodSummary[]
      const yearSummaries = data.Salaries?.YearSummary ?? [] as SalaryYearSummary[]
      setSalaryPeriodSummaries(periodSummaries)
      setSalaryYearSummaries(yearSummaries)
    }).catch(error => {
      setError(error instanceof Error ? error.message : String(error))
    }).finally(() => {
      setLoading(false)
    })
  }

  const resolveYearPeriodSummaries = (): SalaryPeriodSummary[] => {
    if (currentYear === null) {
      return []
    }
    return salaryYearPeriodSummaries[currentYear] ?? []
  }

  const resolveDetails = (): SalaryDetails | null => {
    if (currentKey === null) {
      return null
    }
    return salaryDetails[currentKey] ?? null
  }

  return {
    postForm,
    getYearPeriodSummaries,
    getDetails,
    getSummaries,
    getConfig,
    config: salaryConfig,
    yearPeriodSummaries: resolveYearPeriodSummaries(),
    details: resolveDetails(),
    yearSummaries: salaryYearSummaries,
    periodSummaries: salaryPeriodSummaries,
    getPdf,
    loading: loading || loadingConfig,
    loadingPdf,
    error
  }
}

export default useSalaries
