const apiConfig = {
  mock: {
    useMockApi: true,
    mockApiResponseDelay: 1200,
    apiUrl: null,
    tryOutUrl: null,
    redirectUrl: 'https://localhost:3000/redirect'
  },
  dev: {
    useMockApi: false,
    mockApiResponseDelay: 0,
    apiUrl: 'https://localhost:7205/api/v1.0',
    tryOutUrl: 'https://localhost:7205/api/v1.0',
    redirectUrl: 'https://localhost:3000/redirect'
  },
  test: {
    useMockApi: false,
    mockApiResponseDelay: 0,
    apiUrl: 'https://simobackend-test.silta.fi/api/v1.0',
    tryOutUrl: 'https://simobackend-test.silta.fi/api/v1.0',
    redirectUrl: 'https://esimo-staging.silta.fi/redirect'
  },
  demo: {
    useMockApi: false,
    mockApiResponseDelay: 0,
    apiUrl: 'https://simobackend-demo.silta.fi/api/v1.0',
    tryOutUrl: 'https://simobackend-demo.silta.fi/api/v1.0',
    redirectUrl: 'https://esimo-demo.silta.fi/redirect'
  },
  staging: {
    useMockApi: false,
    mockApiResponseDelay: 0,
    apiUrl: 'https://simobackend-test.silta.fi/api/v1.0',
    tryOutUrl: 'https://simobackend-test.silta.fi/api/v1.0',
    redirectUrl: 'https://esimo-staging.silta.fi/redirect'
  },
  production: {
    useMockApi: false,
    mockApiResponseDelay: 0,
    apiUrl: 'https://simobackend-test.silta.fi/api/v1.0',
    tryOutUrl: 'https://simobackend-test.silta.fi/api/v1.0',
    redirectUrl: 'https://esimo-staging.silta.fi/redirect'
  }
}

const env = process.env.REACT_APP_API_ENV as 'dev' | 'demo' | 'staging' | 'production'
export default apiConfig[process.env.REACT_APP_ENV === 'test' ? 'test' : env]
