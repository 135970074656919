export interface AbsenceErrors {
  StartDate: string | null
  EndDate: string | null
  ICD: string | null
  GivenDate: string | null
  CompanyId: string | null
  TermsAccepted: string | null
  Attachment: string | null
}

export interface NewAbsence {
  StartDate: Date | null
  EndDate: Date | null
  ICD: string | null
  GivenDate: Date | null
  CompanyId: string | null
  TermsAccepted: boolean
  AttachmentBase64: string | null
  AttachmentFilename: string | null
}

export default interface Absence {
  Timestamp: string
  Status: string
  ICD: string
  UserId: string
  CompanyId: string
  FileId: string
  StartDate: string
  EndDate: string
  GivenDate: string
}

export interface ApiAbsence {
  Personal_ID: string
  Business_ID: string
  File_ID: string
  Start_date: string
  End_date: string
  Timestamp: string
  Status: string
  ICD: string
  Given_date: string
}

export const mapApiAbsenceToAbsence = (apiAbsence: ApiAbsence): Absence => {
  return {
    Timestamp: apiAbsence.Timestamp,
    Status: apiAbsence.Status,
    ICD: apiAbsence.ICD,
    UserId: apiAbsence.Personal_ID,
    CompanyId: apiAbsence.Business_ID,
    FileId: apiAbsence.File_ID,
    StartDate: apiAbsence.Start_date,
    EndDate: apiAbsence.End_date,
    GivenDate: apiAbsence.Given_date
  }
}
