interface ReturnProps {
  setDeviceId: (deviceId: string | null) => void
  getDeviceId: () => string | null
  setLocale: (locale: string) => void
  getLocale: () => string | null
  setAgreedTermsVersion: (version: number | null) => void
  getAgreedTermsVersion: () => number | null
  setDemo: (demo: boolean) => void
  getDemo: () => boolean
  setLastReload: (time: number) => void
  getLastReload: () => number | null
  setCookieConsent: (consent: boolean) => void
  getCookieConsent: () => boolean | null
}

const useStorage = (): ReturnProps => {
  const setDeviceId = (deviceId: string | null): void => {
    try {
      if (deviceId === null) {
        window.sessionStorage.removeItem('deviceId')
      } else {
        window.sessionStorage.setItem('deviceId', deviceId)
      }
    } catch (error) {
      console.error(error instanceof Error ? error.message : String(error))
    }
  }

  const getDeviceId = (): string | null => {
    try {
      return window.sessionStorage.getItem('deviceId')
    } catch (error) {
      console.error(error instanceof Error ? error.message : String(error))
      return null
    }
  }

  const setLocale = (locale: string): void => {
    try {
      window.localStorage.setItem('locale', locale)
    } catch (error) {
      console.error(error instanceof Error ? error.message : String(error))
    }
  }

  const getLocale = (): string | null => {
    try {
      return window.localStorage.getItem('locale')
    } catch (error) {
      console.error(error instanceof Error ? error.message : String(error))
      return null
    }
  }

  const setAgreedTermsVersion = (version: number | null): void => {
    try {
      if (version === null) {
        window.localStorage.removeItem('agreedTermsVersion')
      } else {
        window.localStorage.setItem('agreedTermsVersion', `${version}`)
      }
    } catch (error) {
      console.error(error instanceof Error ? error.message : String(error))
    }
  }

  const getAgreedTermsVersion = (): number | null => {
    try {
      const version = window.localStorage.getItem('agreedTermsVersion')
      return version !== null ? parseInt(version, 10) : null
    } catch (error) {
      console.error(error instanceof Error ? error.message : String(error))
      return null
    }
  }

  const setDemo = (demo: boolean): void => {
    try {
      window.sessionStorage.setItem('demo', demo ? 'true' : 'false')
    } catch (error) {
      console.error(error instanceof Error ? error.message : String(error))
    }
  }

  const getDemo = (): boolean => {
    try {
      const demo = window.sessionStorage.getItem('demo')
      return demo === 'true'
    } catch (error) {
      console.error(error instanceof Error ? error.message : String(error))
      return false
    }
  }

  const setLastReload = (time: number): void => {
    try {
      window.localStorage.setItem('lastReload', `${time}`)
    } catch (error) {
      console.error(error instanceof Error ? error.message : String(error))
    }
  }

  const getLastReload = (): number | null => {
    try {
      const time = window.localStorage.getItem('lastReload')
      return time !== null ? parseInt(time, 10) : null
    } catch (error) {
      console.error(error instanceof Error ? error.message : String(error))
      return null
    }
  }

  const setCookieConsent = (consent: boolean): void => {
    try {
      window.localStorage.setItem('cookieConsent', consent ? 'true' : 'false')
    } catch (error) {
      console.error(error instanceof Error ? error.message : String(error))
    }
  }

  const getCookieConsent = (): boolean | null => {
    try {
      const consent = window.localStorage.getItem('cookieConsent')
      return consent !== null ? consent === 'true' : null
    } catch (error) {
      console.error(error instanceof Error ? error.message : String(error))
      return null
    }
  }

  return {
    setDeviceId,
    getDeviceId,
    setLocale,
    getLocale,
    setAgreedTermsVersion,
    getAgreedTermsVersion,
    setDemo,
    getDemo,
    setLastReload,
    getLastReload,
    setCookieConsent,
    getCookieConsent
  }
}

export default useStorage
